/* Estilos básicos para el Navbar */
.navbar {
  background-color: #ffffff; /* Fondo claro para un toque más elegante */
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
  flex-wrap: wrap; /* Permite que el contenido se ajuste en pantallas pequeñas */
}

.navbar-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 150px; /* Tamaño del logo */
  height: auto;
  margin-right: 15px;
  transition: transform 0.3s ease-in-out;
}

.logo:hover {
  transform: scale(1.1);
}

.user-info {
  position: relative;
  display: flex;
  align-items: center;
}

.dropdown-btn {
  background: none;
  border: none;
  color: rgb(0, 0, 0);
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s ease-in-out;
}

.dropdown-btn:hover {
  color: #3498db;
}

.arrow {
  margin-left: 10px;
  transition: transform 0.3s;
}

.arrow.up {
  transform: rotate(180deg);
}

.arrow.down {
  transform: rotate(0deg);
}

/* Estilos del menú desplegable */
.dropdown-menu {
  background-color: #e9e6e6; /* Fondo gris oscuro para el dropdown */
  color: rgb(11, 11, 11); /* Mejor contraste */
  position: absolute;
  top: 40px;
  right: 0;
  min-width: 220px; /* Asegura un ancho mínimo */
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 10px;
  list-style: none;
  margin: 0;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.user-info:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.dropdown-menu li {
  padding: 10px;
  font-size: 14px;
  text-align: left;
  transition: background-color 0.3s ease-in-out;
}

.dropdown-menu li:hover {
  background-color: #bc1a1a81;
  cursor: pointer;
}

/* Botones dentro del dropdown */
.dropdown-buttons {
  display: flex;
  flex-direction: column;
}

.dropdown-buttons button {
  background-color: #2c3e50;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin: 5px 0;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 100%;
  text-align: left;
}

.dropdown-buttons button:hover {
  background-color: #0d0d0d;
  transform: scale(1.05);
}

/* Botón de logout */
.logout-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.logout-btn:hover {
  background-color: #c0392b;
  transform: scale(1.05);
}

/* Media queries para responsividad */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .navbar-header {
    flex-direction: column;
    width: 100%;
  }

  .logo-container {
    margin-bottom: 10px;
  }

  .dropdown-menu {
    width: 90%; /* Ajusta el ancho en pantallas pequeñas */
    top: 50px;
    right: auto;
    left: 5%; /* Centra el dropdown */
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 10px;
  }

  .logo {
    width: 120px; /* Logo más pequeño en dispositivos muy pequeños */
  }

  .dropdown-btn {
    font-size: 14px;
  }

  .dropdown-menu {
    width: 100%;
  }
}
