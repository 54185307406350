/* Contenedor principal */
.page-container {
  width: 100%;
  max-width: 1800px; /* Limite de ancho para escritorio */
  margin: 0 auto;
  min-height: 100vh;
  background-color: #fff;
  padding: 20px;
}

/* Encabezado */
.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  flex-wrap: wrap;
}

.header-left,
.header-center,
.header-right {
  display: flex;
  align-items: center;
}

.header-left {
  flex: 0 0 auto;
}

.header-center {
  flex: 1;
  flex-direction: column;
  text-align: center;
}

.header-right {
  flex: 0 0 auto;
}

/* Logo LAUAK */
.lauak-logo {
  max-height: 60px;
  margin-right: 10px;
}

/* Ícono del encabezado */
.header-icon {
  max-height: 200px;
  margin-bottom: 5px;
}

/* Título */
.header-center h1 {
  margin: 0;
  font-size: 2rem;
}

/* Saludo */
.header-right p {
  margin: 0;
  font-weight: bold;
  font-size: 1.2rem;
}

/* Notificaciones flotantes */
.floating-notification {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 12px 24px;
  border-radius: 5px;
  z-index: 1100;
}

.success {
  background: #4CAF50;
}

.error {
  background: #F44336;
}

/* Mensaje de error */
.error-message {
  color: #F44336;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}

/* Contenedor de la tabla */
.table-container {
  margin: 20px auto;
  max-width: 100%;
  overflow-x: auto;  /* Para que la tabla tenga scroll horizontal en pantallas pequeñas */
  padding: 0 10px;
}

/* Tabla con estilo limpio */
.report-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow: hidden;
  font-family: Arial, sans-serif;
}

/* Encabezado oscuro con texto claro */
.report-table thead {
  background-color: #000;
  color: #fff;
}

.report-table thead th {
  padding: 16px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
}

/* Filas */
.report-table tbody tr {
  border-bottom: 1px solid #ddd;
}

/* Celdas */
.report-table td {
  padding: 16px;
  text-align: center;
  font-size: 16px;
  color: #333;
}

/* Efecto hover en la fila */
.report-table tbody tr:hover {
  background-color: #f5f5f5;
}

/* Estilizar botones dentro de la tabla */
.report-table td button {
  margin: 0 5px;
  padding: 8px 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #575859;
  color: #fff;
  font-size: 14px;
}
.report-table td button:hover {
  opacity: 0.8;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* Modal */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 25px 35px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1100;
  border-radius: 10px;
  width: 31%;
  max-width: 600px;
}

/* Contenido del modal */
.modal-content h3 {
  margin-top: 0;
  text-align: center;
  font-size: 1.8rem;
}

.modal-content p,
.modal-content label {
  margin: 10px 0;
  font-size: 1rem;
}

.modal-content input,
.modal-content select {
  width: 93%;
  padding: 10px;
  margin: 5px 0 15px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.modal-content button {
  width: 97%;
  padding: 12px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 1rem;
}

/* Botón de Cancelar en el modal */
.cancel {
  background-color: #dc3545 !important;
}

/* Botones generales */
button {
  padding: 10px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  opacity: 0.8;
}

/* Responsividad para pantallas medianas y pequeñas */
@media (max-width: 1024px) {
  .page-container {
    padding: 10px;
  }
  
  .page-header {
    padding: 10px 20px;
  }

  .header-center h1 {
    font-size: 1.8rem;
  }

  .report-table thead th,
  .report-table td {
    padding: 12px;
    font-size: 14px;
  }

  .modal {
    padding: 20px 25px;
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .page-header {
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }
  
  .header-left,
  .header-center,
  .header-right {
    justify-content: center;
  }

  /* Permite el scroll horizontal de la tabla */
  .table-container {
    overflow-x: auto;
  }
}

@media (max-width: 480px) {
  .modal {
    padding: 15px 20px;
    width: 95%;
    max-width: 400px;
  }

  .report-table {
    min-width: 480px;
  }
}
