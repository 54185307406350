/* Estilos globales */
:root {
  --color-fondo: #f7f7f7;
  --color-borde: #ddd;
  --color-texto: #333;
  --color-texto-secundario: #555;
  --color-blanco: #fff;
  --color-sombra: rgba(0, 0, 0, 0.1);
  --fuente-base: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--color-fondo);
  font-family: var(--fuente-base);
}

/* Contenedor general para centrar y delimitar el ancho */
.container {
  width: 95%;
  margin: 0 auto;
  padding: 1rem;
}

/* En pantallas grandes, aumenta el ancho máximo */
@media (min-width: 1024px) {
  .container {
    max-width: 2000px; /* Ajusta este valor según tus necesidades */
  }
}

/* Encabezado principal */
.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-blanco);
  height: 70px;
  padding: 0 1.5rem;
  box-shadow: 0 2px 5px var(--color-sombra);
}

.main-header .brand {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.main-header .brand img {
  height: 40px; /* Ajusta el tamaño del logo si lo necesitas */
}

.main-header .brand h1 {
  font-size: 1.2rem;
  color: var(--color-texto);
  margin: 0;
}

.main-header .user-greeting {
  font-size: 1rem;
  color: var(--color-texto-secundario);
}

/* Pantalla de carga */
/* =====================================
   Estilos para componente de carga 
   ===================================== */

/* Animación de carga */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.loading-screen::after {
  content: '';
  display: inline-block;
  width: 80px;
  height: 10px;
  margin-left: 10px;
  border-radius: 5px;
  background: linear-gradient(90deg, #333 0%, #333 50%, rgba(0,0,0,0) 50%);
  background-size: 200% 100%;
  animation: loading-bars 1.5s infinite;
}

@keyframes loading-bars {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* Grupo de zonas */
.zone-group {
  margin: 1.5rem 0;
  padding: 0 0.5rem;
}

.zone-group h3 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  color: var(--color-texto);
  font-weight: 600;
}

/* Contenedor de botones de área (grid responsivo) */
.area-buttons-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
}

/* Botón para cada área (tarjeta) */
.area-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-blanco);
  border: 1px solid var(--color-borde);
  border-radius: 8px;
  box-shadow: 0 2px 5px var(--color-sombra);
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.area-button:hover {
  transform: translateY(-2px);
}

/* Imagen dentro de cada área */
.area-image {
  width: 100%;
  height: 120px; /* Ajusta la altura según tu preferencia */
  object-fit: cover;
  border-radius: 4px;
}

/* Nombre del área */
.area-name {
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 0.95rem;
  color: var(--color-texto);
}

/* Modales */
.modal,
.form-modal,
.animation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content,
.form-modal-content,
.animation-content {
  background: var(--color-blanco);
  padding: 1rem;
  border-radius: 8px;
  position: relative;
  width: 90%;
  max-width: 500px;
  text-align: center;
}

/* Botón de cerrar modal */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Contenido interno del modal */
.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Imagen del modal */
.modal-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 1rem;
}

/* Contenedor de botones de paro */
.paro-buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}

/* Botones de paro */
.paro-button {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  color: var(--color-blanco);
  cursor: pointer;
  font-weight: 600;
}

/* Colores de fondo para cada tipo de paro (ajusta los colores si es necesario) */
.paro-button[data-paro="Mantenimiento"] {
  background-color: #D32F2F;
}
.paro-button[data-paro="Metodos"] {
  background-color: #FF9800;
}
.paro-button[data-paro="Calidad"] {
  background-color: #FDD835;
  color: #000; /* para mejor legibilidad */
}
.paro-button[data-paro="Falta de material"] {
  background-color: #00ACC1;
}
.paro-button[data-paro="Paro programado"] {
  background-color: #757575;
}
.paro-button[data-paro="Cambio de modelo"] {
  background-color: #1E88E5;
}
.paro-button[data-paro="Seguridad"] {
  background-color: #7B1FA2;
}
.paro-button[data-paro="IT"] {
  background-color: #000000;
}
.paro-button[data-paro="Falta de personal"] {
  background-color: #006d36;
}

/* Formulario del modal */
.form-modal textarea {
  width: 100%;
  height: 100px;
  padding: 0.5rem;
  border: 1px solid var(--color-borde);
  border-radius: 4px;
  margin: 1rem 0;
  resize: vertical;
  font-family: var(--fuente-base);
}

.form-modal button {
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}

.form-modal button:nth-child(1) {
  background-color: #2196f3;
  color: var(--color-blanco);
}

.form-modal button:nth-child(2) {
  background-color: #e0e0e0;
}

/* Animación de envío */
.animation-overlay .animation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Spinner */
.animation-overlay .spinner {
  margin: 1rem auto;
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top: 4px solid #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Animación de giro para el spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Media Queries para adaptabilidad */
@media (max-width: 768px) {
  .area-buttons-container {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
}

@media (max-width: 480px) {
  .modal-content,
  .form-modal-content,
  .animation-content {
    width: 95%;
    padding: 0.5rem;
  }

  .area-buttons-container {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .area-button {
    padding: 0.5rem;
  }
}
