.three-buttons-container {
  padding: 20px;
  background-color: #f0f2f5; /* Fondo gris */
  min-height: 100vh; /* Asegura que el fondo cubra toda la ventana */
}

.three-buttons-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white; /* Encabezado blanco */
  padding: 10px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.header-image {
  width: 200px; /* Ajusta el tamaño según tus necesidades */
  height: auto;
}

.exit-button {
  background-color: #ff4d4f;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
}

.buttons-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
}

.button-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  max-width: 100%; /* Asegura que el cuadro no se salga del contenedor */
  overflow: hidden; /* Oculta cualquier contenido que se salga del cuadro */
}

.button-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button-image {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
  object-fit: contain; /* Ajusta la imagen para que se mantenga dentro del cuadro sin cortarse */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #f9f9f9;
  padding: 1px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 500px;
}

.close-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #ff4d4f;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
}

.modal-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-left {
  flex: 1;
  margin-right: 20px;
}

.modal-calendar {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
