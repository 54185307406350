/* Estilos generales del contenedor */
.user-management-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Estilos para el encabezado */
.user-management-header {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.header-image {
  width: 100%;
  max-width: 150px;
  height: auto;
  margin-bottom: 10px;
}

.user-management-header h1 {
  color: #333;
  margin-bottom: 20px;
}

/* Estilos para el mensaje de error */
.error-message {
  color: #ff0000;
  background-color: #ffe6e6;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
}

/* Estilos para el sistema de filtros */
.filters {
  margin-top: 30px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.filters h3 {
  margin-bottom: 15px;
  color: #333;
}

.filter-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.filter-item {
  flex: 1 1 200px;
  display: flex;
  flex-direction: column;
}

.filter-item label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.filter-item input,
.filter-item select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.filter-item input:focus,
.filter-item select:focus {
  border-color: #007bff;
  outline: none;
}

.filter-buttons {
  margin-top: 15px;
  display: flex;
  gap: 10px;
}

.filter-buttons button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.filter-buttons button:hover {
  background-color: #78ff8e;
}

/* Estilos para la tabla de reportes */
.report-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.report-table th, .report-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.report-table th {
  background: #000;
  color: white;
  text-transform: uppercase;
}

.report-table tr:hover {
  background: #f1f1f1;
}

/* Estilos para el formulario de edición */
/* Fondo de superposición */
.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4); /* Fondo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Contenedor del modal */
.modal-container {
  background: #fff; /* Fondo blanco limpio */
  border-radius: 8px; /* Bordes suaves */
  padding: 20px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Sombra elegante */
}

/* Botón de cierre */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #555;
  cursor: pointer;
}

.modal-close:hover {
  color: #000; /* Color más oscuro al pasar */
}

/* Etiquetas e inputs */
label {
  display: block;
  margin: 15px 0 5px;
  font-size: 14px;
  color: #333;
}

input,
select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

input:focus,
select:focus {
  border-color: #007bff;
  outline: none;
}

/* Botones */
button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}


/* Botón de salir */
.logout-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.logout-button:hover {
  background-color: #c82333;
}

/* Responsividad */
@media (max-width: 600px) {
  .filter-group {
    flex-direction: column;
  }
  .filter-item {
    flex: 1 1 100%;
  }
}

/* Global Modal Styles */
/* Global Modal Styles para el contenido emergente */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto; /* Permite scroll si el contenido es alto */
  padding: 20px; /* Espacio para que el modal no toque los bordes */
}

.modal-container {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh; /* Alto máximo para que no se salga de la pantalla */
  overflow-y: auto; /* Si el contenido es muy alto, se podrá hacer scroll */
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Botón de cierre */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: #aaa;
}
.modal-close:hover {
  color: #000;
}

/* Organización de las gráficas en dos columnas */
.charts-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}

/* Cada gráfico ocupará aproximadamente un cuarto de la pantalla */
.chart-item {
  min-height: calc(50vh); /* Un 25% del alto de la ventana */
}

/* Botón para abrir el modal */
.charts-button-container {
  margin: 20px 0;
  text-align: center;
  display: flex;
  justify-content: flex-start;
}
.charts-button-container button {
  padding: 10px 20px;
  font-size: 16px;
  background: #2ecc71;
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;

}
.charts-button-container button:hover {
  background: #27ae60;
}



/* Grafica paretos*/

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Fondo más oscuro para mayor contraste */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto; /* Para manejar contenido que puede exceder la altura */
}

.modal-container {
  background: #f9f9f9; /* Fondo gris claro */
  padding: 25px;
  width: 80%; /* Ancho predeterminado */
  max-width: 600px;
  height: auto; /* Ajusta la altura al contenido */
  min-height: 400px; /* Altura mínima */
  max-height: 90vh; /* Límite de altura para pantallas más pequeñas */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow-y: auto; /* Activar scroll si el contenido excede la altura */
}
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: #333; /* Un color neutral para la X */
  cursor: pointer;
  transition: color 0.3s ease;
}

.modal-close:hover {
  color: #e74c3c; /* Cambia el color al pasar el cursor */
}

/* Responsivo: Altura personalizada para pantallas grandes */
@media screen and (min-width: 1024px) {
  .modal-container {
    min-height: 500px; /* Más alto en pantallas grandes */
  }
}

/* Responsivo: Ajuste para pantallas pequeñas */
@media screen and (max-width: 480px) {
  .modal-container {
    width: 95%;
    min-height: 300px; /* Altura mínima en móviles */
  }
}

@media screen and (max-width: 480px) {
  .modal-container {
    width: 95%; /* Usa la mayor parte del ancho de la pantalla */
    margin: 10px; /* Menor margen en dispositivos muy pequeños */
  }
}


.modal-close:hover {
  color: #ff0000; /* Cambio de color en hover para hacerlo interactivo */
}

.pareto-buttons button {
  margin-right: 10px;
  padding: 8px 15px;
  font-size: 14px;
  color: #fff;
  background-color: #007BFF; /* Azul profesional */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}




.pareto-section {
  margin-top: 25px;
}

.pareto-section label {
  display: block;
  margin-top: 15px;
  font-weight: bold;
  color: #444; /* Color más sobrio para el texto */
}

.chart-container {
  margin-top: 25px;
}


.charts-button-container button {
  margin-right: 10px; /* Espacio entre botones */
}

.charts-button-container button:last-child {
  margin-right: 0; /* Elimina margen del último botón */
}


/* Contenedor de paginación */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Espaciado amplio entre elementos */
  margin: 20px 0;
  font-family: 'Arial', sans-serif;
  font-size: 16px; /* Tamaño de fuente un poco más grande */
  color: #333; /* Color neutro */
}

/* Botones de paginación */
.pagination button {
  padding: 10px 20px;
  background-color: #004080; /* Azul profesional */
  color: white;
  border: 1px solid #004080; /* Borde del mismo color */
  border-radius: 25px; /* Botones redondeados para elegancia */
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra ligera */
}

.pagination button:hover {
  background-color: #0066cc; /* Azul más brillante al pasar el mouse */
  transform: translateY(-3px); /* Animación de elevación */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada */
}

.pagination button:disabled {
  background-color: #cccccc; /* Gris claro para desactivados */
  color: #666666;
  cursor: not-allowed;
  box-shadow: none; /* Sin sombra para desactivados */
  transform: none; /* Sin animación para desactivados */
}

.pagination button:disabled:hover {
  background-color: #cccccc; /* Evitar cambios en hover para botones desactivados */
}

/* Texto de la página */
.pagination span {
  font-weight: bold;
  color: #004080; /* Combina con los botones */
}
