/* Estilos generales del contenedor */
.user-management-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Estilos para el encabezado */
.user-management-header {
  text-align: center;
  margin-bottom: 20px;
}

.header-image {
  width: 100%;
  max-width: 150px;
  height: auto;
  margin-bottom: 10px;
}

.user-management-header h1 {
  color: #333;
  margin-bottom: 20px;
}

/* Estilos para el mensaje de error */
.error-message {
  color: #ff0000;
  background-color: #ffe6e6;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
}

/* Estilos para el botón de mostrar/ocultar formulario */
.toggle-form-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
}

.toggle-form-button:hover {
  background-color: #0056b3;
}

/* Estilos para el formulario de agregar usuario */
.add-user-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.add-user-form input,
.add-user-form select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.add-user-form input:focus,
.add-user-form select:focus {
  border-color: #007bff;
  outline: none;
}

.add-user-form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.add-user-form button:hover {
  background-color: #0056b3;
}

/* Estilos para la tabla de usuarios */
.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.user-table th,
.user-table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.user-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.user-table tr:hover {
  background-color: #f9f9f9;
}

.user-table button {
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.user-table button:hover {
  background-color: #218838;
}

/* Estilos para el formulario de editar usuario */
.edit-user-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.edit-user-form h3 {
  margin-bottom: 10px;
  color: #333;
}

.edit-user-form input,
.edit-user-form select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.edit-user-form input:focus,
.edit-user-form select:focus {
  border-color: #007bff;
  outline: none;
}

.edit-user-form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.edit-user-form button:hover {
  background-color: #0056b3;
}

.edit-user-form button:last-child {
  background-color: #dc3545;
}

.edit-user-form button:last-child:hover {
  background-color: #c82333;
}

/* Estilos para la sección de filtros */
.filters {
  margin-top: 30px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.filters h3 {
  margin-bottom: 15px;
  color: #333;
}

.filters input,
.filters select {
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.filters input:focus,
.filters select:focus {
  border-color: #007bff;
  outline: none;
}

.filters button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.filters button:hover {
  background-color: #0056b3;
}

/* Espaciado entre el formulario de filtros y la tabla */
.filters + .user-table {
  margin-top: 20px;
}

/* MEDIA QUERIES PARA HACER LA PÁGINA RESPONSIVA */

/* Para pantallas pequeñas (móviles) */
@media (max-width: 600px) {
  .user-management-container {
    padding: 10px;
  }

  .header-image {
    max-width: 100px;
  }

  .user-management-header h1 {
    font-size: 20px;
  }

  .add-user-form input,
  .add-user-form select,
  .add-user-form button,
  .filters input,
  .filters select,
  .filters button {
    font-size: 14px;
    padding: 8px;
  }

  .filters {
    padding: 15px;
  }

  /* Los filtros se colocan en una sola columna en dispositivos pequeños */
  .filters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .filters input,
  .filters select {
    width: 100%;
    margin-bottom: 10px;
  }

  .user-table {
    width: 100%;
    font-size: 14px;
  }

  .user-table th,
  .user-table td {
    padding: 8px;
  }

  .user-table button {
    padding: 6px 10px;
  }
}

/* Para pantallas medianas (tabletas) */
@media (max-width: 768px) {
  .user-management-container {
    padding: 15px;
  }

  .header-image {
    max-width: 120px;
  }

  .user-management-header h1 {
    font-size: 22px;
  }

  .add-user-form input,
  .add-user-form select,
  .add-user-form button,
  .filters input,
  .filters select,
  .filters button {
    font-size: 15px;
    padding: 9px;
  }

  .filters {
    display: flex;
    flex-direction: column;
  }

  .filters input,
  .filters select {
    width: 100%;
    margin-bottom: 10px;
  }

  .user-table {
    font-size: 15px;
  }

  .user-table th,
  .user-table td {
    padding: 10px;
  }

  .user-table button {
    padding: 8px 12px;
  }
}

/* Para pantallas grandes */
@media (min-width: 1024px) {
  .user-management-container {
    padding: 20px;
  }

  .user-management-header h1 {
    font-size: 24px;
  }

  .add-user-form input,
  .add-user-form select,
  .add-user-form button,
  .filters input,
  .filters select,
  .filters button {
    font-size: 16px;
    padding: 10px;
  }

  .filters {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .filters input,
  .filters select {
    width: auto;
    margin-right: 10px;
  }

  .user-table {
    font-size: 16px;
  }

  .user-table th,
  .user-table td {
    padding: 12px;
  }

  .user-table button {
    padding: 10px 15px;
  }
}

/* Estilos para el menú desplegable */
.menu-toggle-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Estilos para el botón del menú */
.menu-toggle-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 18px;
}

.menu-toggle-button:hover {
  background-color: #0056b3;
}

/* Menú desplegable */
.dropdown-menu {
  position: absolute;
  top: 50px;
  right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  background-color: white;
  color: #007bff;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
}

.dropdown-item:hover {
  background-color: #007bff;
  color: white;
}

/* Estilos para el botón de salir */
.logout-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.logout-button:hover {
  background-color: #c82333;
}

/* Estilos para la ventana modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #ffffff;
  padding: 70px;
  border-radius: 5px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-family: Arial, sans-serif;
}

/* Estilos para el formulario dentro del modal */
.modal-content h3 {
  color: #333;
  margin-bottom: 20px;
}

.modal-content input,
.modal-content select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
}

.modal-content input:focus,
.modal-content select:focus {
  border-color: #007bff;
  outline: none;
}

.modal-content button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.modal-content button:first-of-type {
  background-color: #007bff;
  color: white;
}

.modal-content button:first-of-type:hover {
  background-color: #0056b3;
}

.modal-content button:last-of-type {
  background-color: #dc3545;
  color: white;
}

.modal-content button:last-of-type:hover {
  background-color: #c82333;
}

/* Estilos para la sección de filtrado */
.filter-section {
  display: flex;
  gap: 10px;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.filter-section input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  width: 200px;
}

.filter-section button {
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-section button:hover {
  background-color: #0056b3;
}

.filter-section button:last-child {
  background-color: #6c757d;
}

.filter-section button:last-child:hover {
  background-color: #5a6268;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 600px) {
  .filter-section {
    flex-direction: column;
    align-items: stretch;
  }

  .filter-section input {
    width: 100%;
  }

  .filter-section button {
    width: 100%;
  }
}
