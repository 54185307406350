.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;



  background-size: cover;
}

.loginBox {
  background: rgb(255, 255, 255);
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 420px;
  text-align: center;
}

.imageContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.loginLogo {
  width: 311px;
  height: auto;
}

.title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  font-weight: bold;
}

.errorMessage {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.inputGroup {
  margin-bottom: 1rem;
  text-align: left;
}

.label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #555;
}

.input {
  width: 92%;
  padding: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s;
}

.input:focus {
  border-color: #007bff;
}

.loginButton {
  width: 100%;
  padding: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.loginButton:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .loginBox {
    width: 90%;
    padding: 2rem;
  }

  .loginLogo {
    width: 130px;
  }

  .title {
    font-size: 1.5rem;
  }

  .input {
    padding: 0.8rem;
    font-size: 1rem;
  }

  .loginButton {
    padding: 0.8rem;
    font-size: 1rem;
  }
}
