/* ======================= ESTILOS GENERALES ======================= */

/* Contenedor principal de la ventana */
.container {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden; /* Evitar que se desborde */
  position: relative;
}

/* Sección de los botones (80% de la pantalla) */
.button-section {
  flex-grow: 1;               /* Ocupa todo el espacio disponible */
  width: 80%;                 /* Para pantallas grandes, ocupa 80% del ancho */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  overflow-x: hidden;
  box-sizing: border-box;
}

/* Fila de textos sobre las columnas */
.text-row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
  height: 20px;
  align-items: center;
}

/* Estilos para los textos en la fila */
.column-title {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

/* Definición específica de columnas para los títulos */
.text-1 {
  grid-column: span 2;
}

.text-2 {
  grid-column: 4;
}

.text-3 {
  grid-column: 6;
}

.text-4 {
  grid-column: 7;
}

/* Grid de 7 columnas para los botones (se respeta el orden original) */
.grid-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
}

/* Cada grupo de columna */
.column-group {
  display: flex;
  flex-direction: column;
  align-items: center;  /* Centra cada grupo para preservar el orden */
  gap: 1rem;
}

/* Columna de botones con centrado */
.button-column {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Espaciado uniforme entre botones */
  align-items: center; /* Centra los botones dentro de cada columna */
}

/* Estilo para cada botón con tamaño fijo */
.button {
  background-color: #6cc773;
  color: white;
  width: 215px;   /* Ancho fijo */
  height: 139px;  /* Altura fija */
  border-radius: 0.2rem;
  text-align: center;
  font-size: 1.5rem;
  cursor: pointer;
  border: none;
  box-sizing: border-box;
  position: relative;
  transition: transform 0.2s ease;
}

.button:hover {
  transform: scale(1.03);
}

/* Cronómetro dentro de cada botón */
.cronometro {
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 17px;
  font-weight: bold;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 3px 6px;
  border-radius: 5px;
  display: inline-block;
}

/* Panel derecho (20% de la pantalla) */
.right-panel {
  width: 20%;
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 100vh;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

/* Imagen en panel derecho */
.right-panel-image {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  object-fit: contain; /* Ajusta la imagen */
}

/* Título en panel derecho */
.right-panel-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Texto central en panel derecho */
.right-panel-text {
  font-size: 1.5rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;
}

/* Texto en la parte inferior del panel derecho */
.right-panel-footer {
  font-size: 2.5rem;
  margin-top: 20px;
  padding-top: 20px;
}

/* Estilos para fallas y paros */
.falla-activa {
  background-color: red !important;
  color: rgb(0, 0, 0);
  font-weight: bold;
}

/* Ejemplo de clases para paros */
.paro-5 {
  background-color: #D32F2F;
}
.paro-6 {
  background-color: #FF9800;
}
.paro-7 {
  background-color: #FDD835;
}
.paro-8 {
  background-color: #00ACC1;
}
.paro-9 {
  background-color: #757575;
}
.paro-10 {
  background-color: #1E88E5;
}
.paro-11 {
  background-color: #7B1FA2;
}
.paro-12 {
  background-color: #006d36;
}
.paro-13 {
  background-color: #000000;
}

/* Botón grande (Prioridad) */
.big-rectangle-button {
  width: 300px;
  height: 400px;
  font-size: 40px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 0.2s ease;
}

.big-rectangle-button:hover {
  transform: scale(1.03);
}

/* Reglas específicas para el botón grande según el id_paro */
.big-rectangle-button.paro-5 {
  background-color: #D32F2F;
}
.big-rectangle-button.paro-6 {
  background-color: #FF9800;
}
.big-rectangle-button.paro-7 {
  background-color: #FDD835;
}
.big-rectangle-button.paro-8 {
  background-color: #00ACC1;
}
.big-rectangle-button.paro-9 {
  background-color: #757575;
}
.big-rectangle-button.paro-10 {
  background-color: #1E88E5;
}
.big-rectangle-button.paro-11 {
  background-color: #7B1FA2;
}
.big-rectangle-button.paro-12 {
  background-color: #006d36;
}
.big-rectangle-button.paro-13 {
  background-color: #000000;
}

/* Cronómetro en botón grande */
.big-rectangle-button .cronometro {
  font-size: 35px;
  font-weight: bold;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
  display: inline-block;
}

/* ======================= LEYENDA GLOBAL ======================= */

/* Leyenda global: ubicada en la parte superior de la página */
.global-legend {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 15px 30px;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2); /* Fondo degradado sutil */
  border-bottom: 2px solid #aaa;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative; /* Cambia a fixed si deseas que permanezca visible al hacer scroll */
  z-index: 3000;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.legend-box {
  width: 28px;
  height: 28px;
  border-radius: 6px;
  border: 2px solid #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.legend-text {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

/* ======================= INFO-BOX ======================= */

/* Info-box: se posiciona dinámicamente a través de estilos en línea */
.info-box {
  position: absolute;
  left: var(--info-box-left, 0px);  /* Por defecto 0px si no se define */
  top: var(--info-box-top, 0px);    /* Por defecto 0px si no se define */
  width: 300px;
  padding: 15px;
  border: 1px solid #cccccc85;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  animation: fadeIn 0.2s ease-in-out;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 0.8em;
}

/* ======================= RELOJ ======================= */

/* Reloj en tiempo real */
.clock {
  margin-top: 20px;
  font-size: 25px;
  font-weight: bold;
  color: #797979;
  text-align: center;
}

/* ======================= ANIMACIONES ======================= */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ======================= MEDIA QUERIES ======================= */

/* Ajustes para pantallas medianas (tablets, etc.) */
@media (max-width: 992px) {
  .column-title {
    font-size: 1.2rem;
  }
  .button {
    width: 180px;
    height: 120px;
    font-size: 1.1rem;
  }
  .big-rectangle-button {
    width: 250px;
    height: 300px;
    font-size: 30px;
  }
  .big-rectangle-button .cronometro {
    font-size: 24px;
  }
  .right-panel-title {
    font-size: 2.5rem;
  }
  .right-panel-footer {
    font-size: 2rem;
  }
}

/* Ajustes para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    height: auto;
  }
  .button-section {
    width: 100%;
    order: 2;
  }
  .right-panel {
    width: 100%;
    order: 1;
    height: auto;
    margin-bottom: 20px;
  }
  .text-row {
    display: none;
  }
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .button {
    width: 140px;
    height: 90px;
    font-size: 1rem;
  }
  .big-rectangle-button {
    width: 220px;
    height: 250px;
    font-size: 24px;
  }
  .big-rectangle-button .cronometro {
    font-size: 18px;
  }
  .global-legend {
    position: static;
    border-bottom: none;
  }
}

/* Ajustes para pantallas muy pequeñas (por debajo de 480px) */
@media (max-width: 480px) {
  .button {
    width: 120px;
    height: 70px;
    font-size: 0.9rem;
  }
  .big-rectangle-button {
    width: 180px;
    height: 220px;
    font-size: 20px;
  }
  .big-rectangle-button .cronometro {
    font-size: 16px;
  }
  .info-box {
    position: static;
    margin-top: 10px;
    width: auto;
    right: auto;
    top: auto;
    border: 1px solid #ccc;
  }
  .info-box p {
    font-size: 0.9rem;
  }
}

/* Sobrescribimos el comportamiento en pantallas móviles para el grid */
@media (max-width: 768px) {
  .grid-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    overflow-x: auto;
    width: 100%;
    grid-template-columns: none !important;
  }
  .column-group {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .button {
    width: 150px;
    height: 100px;
    font-size: 1rem;
  }
}
